div {
	font-size: 95%;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

textarea {
	width: 300px;
	height: 150px;
}

.input {
	width: 300px;
	height: 30px;
}

.navbar {
	background-color: #2c3e50;
}

.navlink {
	border: 3px solid #12b1b4;
	color: Linen;
	font-weight: bold;
	margin-right: 5%;
}

.res {
	background-color: linen;
	height: 100%;
}

.home_main {
	height: 100%;
}

.blog_posts {
	width: 50%;
	margin: auto;
}

.contact_form_input {
	flex-direction: column;
}

.contact_input {
	padding-top: 3%;
	font-weight: bold;
	text-align: center;
}

.res_header {
	display: flex;
	justify-content: space-between;
}

.resume_name {
	width: 10%;
}

.resume_contact {
	text-align: right;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.top_pad {
	padding-top: 3%;
}

.side_pad {
	margin-right: 25%;
	margin-left: 25%;
}

.skill_box {
	display: flex;
	justify-content: space-evenly;
}

.shrink {
	width: 10%;
}

.res_header2 {
	color: #0b6d6e;
}

h1,
p {
	margin: 0;
	font-weight: bold;
}

h2 {
	margin: 0;
}

.text_center {
	text-align: center;
}

h3 {
	margin: 0;
	padding-top: 3%;
}

li {
	font-weight: bold;
}

.underline_center_top {
	text-decoration: underline;
	text-align: center;
	padding-top: 3%;
}
